<template>
  <div>
    <jzsy-header title="操作指引">
      <div slot="right">
        <div class="menu-btn" @click="showMenu">目录</div>
      </div>
    </jzsy-header>
    <md-popup v-model="menuShow" position="right">
      <div class="menu-content">
        <h1 class="menu-title">目录</h1>
        <ul>
          <li><md-button type="link" @click="$router.push('#账号开通')">1. 账号开通</md-button></li>
          <li><md-button type="link" @click="$router.push('#登录')">2. 账号登录</md-button></li>
          <li><md-button type="link" @click="$router.push('#产品匹配')">3. 产品匹配</md-button></li>
          <li><md-button type="link" @click="$router.push('#在线进件')">4. 在线进件</md-button></li>
          <li><md-button type="link" @click="$router.push('#匹配历史')">5. 匹配历史</md-button></li>
          <li><md-button type="link" @click="$router.push('#修改密码')">6. 修改密码</md-button></li>
          <li><md-button type="link" @click="$router.push('#人员管理')">7. 人员管理</md-button></li>
        </ul>
      </div>
    </md-popup>
    <div class="content">
      <div class="quote">欢迎使用<b>橘子快贷</b>。</div>
      <div>橘子快贷是橘子税银为合作机构提供的营销赋能工具。通过该工具可以实现产品智能匹配、线上进件、产品大纲等功能。我们旨在为我们的合作伙伴提供切实有用的系统工具。在使用过程中，欢迎各位提供宝贵的意见，我们将努力满足您的需求。</div>
      <h1 id="账号开通">1. 账号开通</h1>
      <div class="quote">
        PS: 该系统仅供我们的合作伙伴免费使用，有合作意愿的机构请与我们联系。联系电话：18116597933（傅先生）
      </div>
      <div>
        <p>主账号需由我们的后台为您开通，开通主账号后，您可以为您的机构开通子账号（原则上可以开通无限个）。</p>
        <p>账号开通后请及时修改密码。</p>
      </div>
      <h1 id="登录">2. 账号登录</h1>
      <div>
        <p>输入手机号和密码即可实现登录。</p>
        <p><i>图1：登录界面</i></p>
        <p><img src="../assets/guide/登录.png"></p>
        <p>为方便您下次使用我们的系统，请关注我们微信公众号。</p>
      </div>
      <h1 id="产品匹配">3. 产品匹配</h1>
      <p>登录成功后会立即进入产品匹配页面。也可以点击首页上的<code>产品匹配</code>按钮进入产品匹配页面。</p>
      <p><i>图2：产品匹配</i></p>
      <p><img src="../assets/guide/产品匹配.png"></p>
      <p>输入企业的相关信息，点击提交按钮，系统将进行自动匹配符合的产品。其中：</p>
      <div class="quote">
        <p>信用代码或企业名称<b>必填</b></p>
        <p>申请人年龄、年开票额、年交税额、税务等级为<b>选填</b>， 完整填写可以得到更精确的结果</p>
      </div>
      <p><i>图3：匹配结果（符合准入）</i></p>
      <p><img src="../assets/guide/匹配结果(符合准入).jpg"></p>
      <p>匹配结果中，我们会给出各产品的详细信息和申请条件，以及风险提示。</p>
      <p>对于不符准入的产品，我们会给出不符合的原因，供您参考。</p>
      <h1 id="在线进件">4. 在线进件</h1>
      <p>部分可以在线申请的产品，可以点击<code>立即申请</code>。系统会进入到税务授权页面，输入电子税务局密码，以及法人手机号接收到的手机验证码即可完成税务授权。</p>
      <p><i>图4：税务授权</i></p>
      <p><img src="../assets/guide/税务授权.png"></p>
      <h1 id="匹配历史">5. 匹配历史</h1>
      <p>对于以匹配过的企业，可以直接进入菜单<code>匹配历史</code>中查看匹配结果。</p>
      <h1 id="修改密码">6. 修改密码</h1>
      <p>修改自己的账号密码，建议初次登录时及时修改自己的账号密码。</p>
      <h1 id="人员管理">7. 人员管理</h1>
      <p>对于主账号，可以在此对您所管理人员进行账号管理。</p>
      <p><img src="../assets/guide/人员管理.png"></p>
      <h2 id="添加人员">7.1. 添加人员</h2>
      <p>进入<code>人员管理</code>页面，点击左上角图标，选择<code>添加新员工</code>即可添加人员账号。</p>
      <p>输入信息：</p>
      <div class="quote">
        <p>人员姓名（必填）</p>
        <p>人员手机号（必填）</p>
      </div>
      <h2 id="修改人员信息">7.2. 修改人员信息</h2>
      <p>点击人员列表中对应的操作按钮，可以选择对人员信息进行修改、停用和重置密码。</p>
      <div class="quote">
        PS: 不可以对主账号进行<code>停用</code>操作，如果主账号人员发生变动，建议进行账号交接，并及时修改信息和密码。
      </div>
      <h2 id="停用账号管理">7.3. 停用账号管理</h2>
      <p>进入<code>人员管理</code>页面，点击左上角图标，选择<code>停用账号管理</code>可以对已停用的账号进行启用操作。</p>
    </div>
  </div>
</template>

<script>
  import { Popup, PopupTitleBar } from 'mand-mobile'

  export default {
    components: {
      [Popup.name]: Popup,
      [PopupTitleBar.name]: PopupTitleBar
    },
    data() {
      return {
        menuShow: false
      }
    },
    methods: {
      showMenu() {
        this.menuShow = true
      }
    }
  }

</script>

<style scoped>
  .menu-btn {
    margin-right: 20px;
  }
  .menu-content {
    width: 300px;
    height: 100%;
    background-color: #fff;
  }
  .menu-content ul li {
    font-size: 32px;
    margin: 40px;
  }
  .menu-title {
    margin: 0;
    padding: 20px;
  }
  .content{
    padding: 20px 40px;
  }
  .content * {
    font-size: 32px;
    line-height: 48px;
  }
  .content .quote {
    border-left: 8px solid #e3e3e3;
    padding: 10px 20px;
    color: #848484;
    margin: 20px 0;
  }
  .content h1 {
    font-size: 46px;
  }
  .content h2 {
    font-size: 36px;
  }
  .content h1, .content h2 {
    padding-bottom: 32px;
    border-bottom: 1px solid #e3e3e3;
  }
  .content p {
    margin: 15px 0;
  }
  .content i {
    color: #848484;
  }
  .content img {
    max-width: 100%;
    border: 1px solid #e3e3e3;
  }
  .content code {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background-color: #e2e2e2;
    margin: 0 5px;
    padding: 0 5px;
  }
</style>